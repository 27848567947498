import React, { useEffect } from "react";
import Header from "@/components/Header/Header";
import SubHeader from "@/components/About/SubHeader";
import ABoutUS from "@/components/Home/About";
import People from "@/components/About/People";
import Infographics from "@/components/About/Infographics";
import Agents from "@/components/About/Agents";
import Testimonials from "@/components/Home/Testimonials";
import Swiper from "./Swiper";

const About = () => {
  return (
    <>
      <Header />
      <SubHeader title="About" />
      <ABoutUS />
      <People />
      <Infographics />
      <Agents />
      <Testimonials />
    </>
  );
};

export default About;
