import React from "react";

const People = () => {
  return (
    <div className="section pt-0 work-experience-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="experience-wrapper">
              <div className="experience-inner">
                <div className="experience-inner-image">
                  <img
                    src="images/about1.jpg"
                    className="full-width"
                    alt="img"
                  />
                </div>
                <div className="experience-inner-content" id="people">
                  <h3>Why Do People Believe Us</h3>
                  <div className="row align-items-center">
                    <div className="col-12">
                      <p>
                        We’re customer-friendly and experts in dealing with all
                        kinds of bicycles. We simply do our best to ensure that
                        the client is completely satisfied with our services.
                      </p>
                      <p>
                        We provide our experience for the benefit of the cycling
                        community cause we love bicycles and the job that we do.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default People;
