import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { useViewport } from "@/hooks/useViewport";
// import required modules
import { Pagination, Autoplay } from "swiper";

export default function Sw() {
  const { isMobile, isTablet, isDesktop, isWidescreen } = useViewport();

  return (
    <>
      <Swiper
        slidesPerView={
          (isMobile && 1) ||
          (isTablet && 3) ||
          (isDesktop && 4) ||
          (isWidescreen && 5) ||
          1
        }
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
        }}
        modules={[Pagination, Autoplay]}
        className="section section-padding agents agents-slider container"
      >
        <div className="container agents-slider">
          <SwiperSlide>
            <div className="acr-agent">
              <div className="listing-badge featured">
                <i className="fas fa-star"></i>
              </div>
              <div className="acr-dots-wrapper acr-agent-thumb">
                <div className="acr-dots"></div>
                <a href="agent-details.html">
                  <img src="images/agents/1.jpg" alt="agent" />
                </a>
              </div>
              <div className="acr-agent-body">
                <h6>
                  {" "}
                  <a href="agent-details.html">Susan Reed</a>{" "}
                </h6>
                <p>
                  Bicycles are my passion and my life. I am happy to help the
                  cycling community every day.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="acr-agent">
              <div className="listing-badge featured">
                <i className="fas fa-star"></i>
              </div>
              <div className="acr-dots-wrapper acr-agent-thumb">
                <div className="acr-dots"></div>
                <a href="agent-details.html">
                  <img src="images/agents/2.jpg" alt="agent" />
                </a>
              </div>
              <div className="acr-agent-body">
                <h6>
                  {" "}
                  <a href="agent-details.html">Liam Stewart</a>{" "}
                </h6>
                <p>
                  We provide an accurate and fast solution for bike problems due
                  to our experience and knowledge of the complexity of its
                  systems.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="acr-agent">
              <div className="listing-badge featured">
                <i className="fas fa-star"></i>
              </div>
              <div className="acr-dots-wrapper acr-agent-thumb">
                <div className="acr-dots"></div>
                <a href="agent-details.html">
                  <img src="images/agents/3.jpg" alt="agent" />
                </a>
              </div>
              <div className="acr-agent-body">
                <h6>
                  {" "}
                  <a href="agent-details.html">Michael Smith</a>{" "}
                </h6>
                <p>
                  Constant learning is the key to remaining a good mechanic over
                  the years.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="acr-agent">
              <div className="listing-badge featured">
                <i className="fas fa-star"></i>
              </div>
              <div className="acr-dots-wrapper acr-agent-thumb">
                <div className="acr-dots"></div>
                <a href="agent-details.html">
                  <img src="images/agents/4.jpg" alt="agent" />
                </a>
              </div>
              <div className="acr-agent-body">
                <h6>
                  {" "}
                  <a href="agent-details.html">Noah Evans</a>{" "}
                  <a href="agent-details.html"></a>{" "}
                </h6>
                <p>
                  It is our responsibility to know each bicycle's functioning
                  and provide personalized customer service.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </>
  );
}
