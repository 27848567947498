import React from "react";
import SW from "@/components/About/Swiper";

const Agents = () => {
  return (
    <div className="section section-padding agents">
      <div className="container">
        <div
          className="section-title-wrap section-header flex-header"
          id="staff"
        >
          <div className="section-title-text">
            <h5 className="custom-primary">Our Qualified Team</h5>
            <h2 className="title">Meet Our Staff</h2>
          </div>
        </div>

        <div className="agents-slider">
          <SW />
        </div>
      </div>
    </div>
  );
};

export default Agents;
