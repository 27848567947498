import React from 'react'

const Infographics=()=> {
  return (
    <div className="section section-padding bg-cover bg-center bg-parallax dark-overlay dark-overlay-2" 
    // style="background-image: url('assets/img/banner/3.jpg')"
    style={{backgroundImage:"url('images/banner/3.jpg')"}}
    >
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="acr-infographic-item">
            <i className="flaticon-bicycle"></i>
            <h4>5,658</h4>
            <p>Tune-ups</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="acr-infographic-item">
            <i className="flaticon-helmet"></i>
            <h4>2,200</h4>
            <p>Overhauls</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="acr-infographic-item">
            <i className="flaticon-location"></i>
            <h4>5,312</h4>
            <p>Refits</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="acr-infographic-item">
            <i className="flaticon-bicycle-parking"></i>
            <h4>4,335</h4>
            <p>Repairs</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}

export default Infographics