import React from "react";
import Layout from "@/components/Layout";
import About from "@/components/About";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { useLocation } from "@reach/router";
import SEO from "@/components/seo";

function Ab() {
  return (
    <Layout>
      <SEO title="About" canonical={useLocation().host} />
      <Header />
      <About />
      <Footer />
    </Layout>
  );
}

export default Ab;
